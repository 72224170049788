import React, { Component } from 'react';
import Loadable from 'react-loading-overlay';
import { I18n } from 'react-i18nify';
import qs from 'qs';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import * as Sentry from '@sentry/browser';
import TShirtViewer from '../../configurator/TShirtViewer';
import { get } from '../../api/quotes';
import NavPreview from './NavPreview';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { location, alert } = this.props;
    const { search } = location;
    const { id } = qs.parse(search.substr(1));
    if (!id) return;

    this.setState({ loading: true });
    get(id)
      .then(({ data }) => {
        const { config } = data;
        this.loadModel(config);
      })
      .catch((err) => {
        this.setState({ loading: false });
        alert.error(I18n.t('ERROR'));
        Sentry.captureException(err);
      });
  }

  loadModel(config) {
    this.configurator = new TShirtViewer(document.getElementById('configurator'));
    this.configurator.setMode = () => null;
    this.configurator.on('modelLoaded', () => {
      this.setState({ loading: false });
    });
    this.configurator.loadModelAsync(config);
  }

  render() {
    const { loading } = this.state;
    return (
      <Loadable
        active={loading}
        text={I18n.t('LOADING')}
      >
        <NavPreview />

        <section className="cont-configurator">
          <div className="row">
            <div className="col-12">
              <div id="configurator" />
            </div>
          </div>
        </section>
      </Loadable>
    );
  }
}

Preview.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  alert: PropTypes.shape({
    error: PropTypes.func,
  }).isRequired,
};

export default withAlert(Preview);
