import React, { Component } from 'react';
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { I18n, Translate } from 'react-i18nify';
import PropTypes from 'prop-types';

const { REACT_APP_ENABLE_RETURN_URL } = process.env;
const enableReturnUrl = REACT_APP_ENABLE_RETURN_URL === 'true';

// eslint-disable-next-line react/prefer-stateless-function
class NavCustomer extends Component {
  render() {
    const { history } = this.props;
    return (
      <Navbar color="light" light expand="md">
        <NavbarBrand href={enableReturnUrl ? I18n.t('THANK_YOU_RETURN_URL') : '/'}>
          <img src="/images/alka-logo.png" alt="Alka Sport" className="img-fluid" />
        </NavbarBrand>
        <div className="content-elements">
          <div className="ml-auto">
            <button onClick={() => history.goBack()} type="button" className="btn uppercase p-r-i-30 p-l-i-30 min-w-250">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-570 871 51.2 22" className="arrow-back">
                <path className="arrow" d="M-558.3 872.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7 0 .3.2.6.4.8l9.9 9.9c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-8.3-8.3h46.7c.6 0 1-.4 1-1s-.4-1-1-1h-46.8l8.4-8.3z" />
              </svg>
              <Translate value="BACK" />
            </button>
          </div>
        </div>
      </Navbar>
    );
  }
}

NavCustomer.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default NavCustomer;
