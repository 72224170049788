export const CLEAR = 'CLEAR';
export const TOOLTIPS = 'TOOLTIPS';
export const SET_CONFIGURATION_ID = 'SET_CONFIGURATION_ID';
export const SET_MODEL = 'SET_MODEL';
export const SET_MODEL_NAME = 'SET_MODEL_NAME';
export const SET_VARIANT = 'SET_VARIANT';
export const SET_TEXTURE = 'SET_TEXTURE';
export const SET_COLORS = 'SET_COLORS';
export const SET_TEXTS = 'SET_TEXTS';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_QUALITY = 'SET_QUALITY';
