import _ from 'lodash';
import * as THREE from 'three';
import {OBJECT_TYPES} from '../constants';
import ImageItem from './ImageItem';
import TextItem from './TextItem';

export default function(data) {
    const opts = _.omit(data, 'point', 'type', 'uv');
    switch (data.type) {
        case OBJECT_TYPES.IMAGE:
            opts.img = document.createElement('img');
            opts.img.src = opts.url;
            return new ImageItem(opts, new THREE.Vector3(data.point[0], data.point[1], data.point[2]), new THREE.Vector2(data.uv[0], data.uv[1]));
        case OBJECT_TYPES.TEXT:
            return new TextItem(opts, new THREE.Vector3(data.point[0], data.point[1], data.point[2]), new THREE.Vector2(data.uv[0], data.uv[1]));
        default:
            throw new Error(`Invalid requested type "${data.type}".`);
    }
}
