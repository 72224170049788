/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { UncontrolledTooltip } from 'reactstrap';

const Variant = ({
  variants,
  variant,
  onChangeVariant,
  tooltips,
}) => (
  variants && variants.length
    ? (
      <div className="cont-variants">
        <h4><Translate value="SELECT_A_VARIANT" /></h4>
        <hr />
        <div className="row">
          {
          variants.map(({ id, name, image }, index) => {
            // const active = id === variant;
            const active = variant ? id === variant : !index;
            return (
              <div key={id} className="col-sm-6">
                <div id={`select-variant-${index}`}>
                  <div
                    className={`cont-variant ${active ? 'active' : ''}`}
                    onClick={() => onChangeVariant(id)}
                    role="button"
                    onKeyPress={() => null}
                  >
                    <div className="variant-img" style={{ backgroundImage: `url(${image})` }} />
                    <h4>{name}</h4>
                    <button type="button" className={`btn ${active ? 'active' : ''}`}>
                      <Translate value="SELECT" />
                    </button>
                  </div>
                  {
                    tooltips && (
                      <UncontrolledTooltip placement="bottom" target={`select-variant-${index}`}>
                        <Translate value="TOOLTIPS.SELECT_VARIANT" />
                      </UncontrolledTooltip>
                    )
                  }
                </div>
              </div>
            );
          })
        }
        </div>
      </div>
    )
    : ''
);

Variant.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string.isRequired,
  onChangeVariant: PropTypes.func.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

Variant.defaultProps = {
  variants: [],
};

const mapStateToProps = state => ({
  variant: state.variant,
});

export default connect(mapStateToProps)(Variant);
