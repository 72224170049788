import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { Translate } from 'react-i18nify';
import { number2Color } from '../../helper/colors';
import PantonePicker from '../PantonePicker';

class ColorItem extends Component {
  changeColor(newColor) {
    const { part = {}, onChangeColor } = this.props;
    const { key } = part;
    const { hex, name: pantoneName, pantone } = newColor;
    onChangeColor({
      key,
      hex: hex.replace('#', '0x'),
      pantone,
      pantoneName,
    });
  }

  render() {
    const {
      part = {},
      open,
      onTogglePicker,
      itemIndex,
      tooltips,
    } = this.props;
    const {
      key = '',
      color = '',
      pantone = '',
      pantoneName = '',
    } = part;
    const hex = number2Color(color);

    return (
      <div>
        <div key={key}>
          <div className="row cont-color" onClick={() => onTogglePicker(key)} role="presentation" id={`color-item-${itemIndex}`}>
            <div className="col-6">
              <h4>{key}</h4>
            </div>
            <div className="col-6 text-right">
              <div>
                {pantoneName}
                {pantone ? ` (${pantone})` : ''}
                {' '}
                <span className="color-preview" style={{ backgroundColor: `${hex}` }}>{' '}</span>
              </div>
            </div>
          </div>
          {
            open && (
              <PantonePicker
                tooltips={tooltips}
                itemIndex={itemIndex}
                color={{ hex, pantone }}
                onChange={newColor => this.changeColor(newColor)}
              />
            )
          }
          <hr />
        </div>
        {
          tooltips && (
            <UncontrolledTooltip placement="bottom" target={`color-item-${itemIndex}`}>
              <Translate value="TOOLTIPS.COLOR_ITEM" />
            </UncontrolledTooltip>
          )
        }
      </div>
    );
  }
}

ColorItem.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  part: PropTypes.shape({
    key: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    pantone: PropTypes.string,
    pantoneName: PropTypes.string,
  }).isRequired,
  onChangeColor: PropTypes.func.isRequired,
  onTogglePicker: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default ColorItem;
