import { CLEAR, SET_VARIANT } from '../actions/names';

const initialState = '';

const reducer = (state = initialState, { type, variant = '' }) => {
  switch (type) {
    case SET_VARIANT:
      return variant;
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
