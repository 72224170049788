import * as Sentry from '@sentry/browser';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom';
import { I18n } from 'react-i18nify';
import { Provider as AlertProvider } from 'react-alert';
import FontFaceObserver from 'fontfaceobserver';
import store from './store';
// import registerServiceWorker from './registerServiceWorker';
import Home from './components/Home';
import Configuration from './components/Configuration';
import Customer from './components/Customer';
import NotFound from './components/NotFound';
import ThankYou from './components/ThankYou';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import translations from './translations';
import Basic from './components/Alerts/Basic';
import Preview from './components/Preview';
import { FONTS } from './constants';

const {
  NODE_ENV: environment,
  REACT_APP_SENTRY_DSN: dsn,
  REACT_APP_FONT_LOADING_TIMEOUT = '3000',
} = process.env;

if (dsn) Sentry.init({ dsn, environment });

I18n.setTranslations(translations);

let locale = 'en';
const { Intl } = window || {};

if (Intl && typeof Intl === 'object') {
  const { language = '' } = navigator || {};
  locale = language.startsWith('it-') ? 'it' : locale;
}

I18n.setLocale(locale);

/* Pre load custom font */
const fontTimeout = parseInt(REACT_APP_FONT_LOADING_TIMEOUT, 10);
Promise.all(FONTS.filter(({ custom }) => custom).map(({ value = '' }) => {
  const fontFace = value.split(',')[0];
  return new FontFaceObserver(fontFace).load(null, fontTimeout);
}))
  .catch((err) => {
    Sentry.captureException(err);
    console.log('Error loading fonts');
    console.log(err);
  });

const alertOptions = {
  position: 'bottom center',
  timeout: 5000,
  transition: 'fade',
};

render(
  <AlertProvider template={Basic} {...alertOptions}>
    <Provider store={store}>

      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/configuration" component={Configuration} />
            <Route exact path="/customer" component={Customer} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/preview" component={Preview} />
            <Route exact path="/404" component={NotFound} />
            <Redirect from="*" to="/404" />
          </Switch>
        </div>
      </Router>

    </Provider>
  </AlertProvider>,
  document.getElementById('root'),
);

// registerServiceWorker();
