import { I18n } from 'react-i18nify';
import cloneDeep from 'lodash/cloneDeep';
import getAX512 from './AX512';
import getAX512RM from './AX512RM';
import getAX512TF from './AX512TF';
import getAX513 from './AX513';
import getAX514 from './AX514';
// eslint-disable-next-line import/named
import getSalopette, { salopetteCodes, shortenCode } from './salopette';
import { DEFAULT_QUALITY, IS_MOBILE } from '../constants';

export const list = () => {
  const models = [
    {
      code: 'AX512',
      description: I18n.t('MODELS.AX512.DESCRIPTION'),
      image: '/images/AX512/AX512.png',
      baseVariant: 'base',
    },
    {
      code: 'AX512RM',
      description: I18n.t('MODELS.AX512RM.DESCRIPTION'),
      image: '/images/AX512RM/AX512RM.png',
      baseVariant: 'base',
    },
    {
      code: 'AX512TF',
      description: I18n.t('MODELS.AX512TF.DESCRIPTION'),
      image: '/images/AX512TF/AX512TF.png',
      baseVariant: 'base',
    },
    {
      code: 'AX513',
      description: I18n.t('MODELS.AX513.DESCRIPTION'),
      image: '/images/AX513/AX513.png',
      baseVariant: 'base',
    },
    {
      code: 'AX514',
      description: I18n.t('MODELS.AX514.DESCRIPTION'),
      image: '/images/AX514/AX514.png',
      baseVariant: 'base',
    },
    ...salopetteCodes.map(code => ({
      code: shortenCode(code),
      originalCode: code,
      image: `/images/${code}/${code}.png`,
      baseVariant: 'base',
    })),
  ];
  return Promise.resolve(cloneDeep(models));
};

export const getBaseVariant = code => list()
  .then((models) => {
    const model = models.find(({ code: modelCode }) => modelCode === code);
    return model ? model.baseVariant : '';
  });

export const get = (code) => {
  let model;
  switch (code) {
    case 'AX512':
      model = getAX512();
      break;
    case 'AX512RM':
      model = getAX512RM();
      break;
    case 'AX512TF':
      model = getAX512TF();
      break;
    case 'AX513':
      model = getAX513();
      break;
    case 'AX514':
      model = getAX514();
      break;
    default:
      salopetteCodes.forEach((originalCode) => {
        if (code !== shortenCode(originalCode)) return;
        model = getSalopette(originalCode);
      });
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  if (!model) return Promise.reject('Invalid model');
  const { config, variants } = cloneDeep(model);
  config.quality = DEFAULT_QUALITY;
  if (IS_MOBILE) {
    config.groups = [];
    config.noMobile = true;
  }
  return Promise.resolve({ config, variants });
};
