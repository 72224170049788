import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import isEmpty from 'lodash/isEmpty';
import TextForm from './TextForm';
import { MODES } from '../../configurator/constants';

const Text = ({
  mode, texts, onAddText, onRemoveText, tooltips, onTextZoom, onTextRotate,
}) => (
  <div>
    <h4><Translate value="INSERT_TEXT" /></h4>
    <hr />
    <TextForm
      tooltips={tooltips}
      textIndex={0}
      onSubmit={onAddText}
      disabled={mode === MODES.PICK_TEXT_POSITION}
    />
    {
      !isEmpty(texts) && (
        <div className="p-t-10">
          <h4><Translate value="INSERTED_TEXTS" /></h4>
          {
            texts.map((text, index) => (
              <TextForm
                tooltips={tooltips}
                textIndex={index + 1}
                key={text.id}
                onSubmit={onAddText}
                onRemoveText={onRemoveText}
                onTextZoom={onTextZoom}
                onTextRotate={onTextRotate}
                text={text}
              />
            ))
          }
        </div>
      )
    }
  </div>
);

Text.propTypes = {
  mode: PropTypes.string.isRequired,
  onAddText: PropTypes.func.isRequired,
  onRemoveText: PropTypes.func.isRequired,
  onTextRotate: PropTypes.func.isRequired,
  onTextZoom: PropTypes.func.isRequired,
  texts: PropTypes.arrayOf(PropTypes.object).isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default Text;
