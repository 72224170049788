export const FONTS = [
  {
    name: 'Arial',
    value: 'Arial, Helvetica, sans-serif',
  },
  {
    name: 'Arial Black',
    value: '"Arial Black", Gadget, sans-serif',
  },
  {
    name: 'Comic Sans MS',
    value: '"Comic Sans MS", cursive, sans-serif',
  },
  {
    name: 'Courier New',
    value: '"Courier New", Courier, monospace',
  },
  {
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    name: 'Impact',
    value: 'Impact, Charcoal, sans-serif',
  },
  {
    name: 'Lucida Console',
    value: '"Lucida Console", Monaco, monospace',
  },
  {
    name: 'Lucida Sans Unicode',
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  },
  {
    name: 'Palatino Linotype',
    value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  },
  {
    name: 'Times New Roman',
    value: '"Times New Roman", Times, serif',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, Geneva, sans-serif',
  },
  {
    name: 'Trebuchet MS',
    value: '"Trebuchet MS", Helvetica, sans-serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
  },
  {
    name: 'Airstrike Condensed',
    value: 'Airstrike_Condensed, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Amarillo',
    value: 'Amarillo, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Ancherr',
    value: 'Ancherr, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Atelier Omega',
    value: 'Atelier_Omega, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Azmie Australia',
    value: 'Azmie_Australia, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Azmie South Korea',
    value: 'Azmie_SouthKorea, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Beagle Brigade AU',
    value: 'Beagle_Brigade_AU, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Falcon Punch',
    value: 'Falcon_Punch_Condensed, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Master Droid',
    value: 'Master_Droid, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Shock Horror',
    value: 'Shock_Horror, Verdana, sans-serif',
    custom: true,
  },
  {
    name: 'Zeroes 2',
    value: 'Zeroes_2, Verdana, sans-serif',
    custom: true,
  },
];

export const ANDROID_REGEX = /android/i;
export const IOS_REGEX = /iPad|iPhone|iPod/i;
export const FIREFOX_REGEX = /firefox/i;
const { platform = '', userAgent = '' } = navigator;
const { MSStream } = window;
// eslint-disable-next-line max-len
export const IS_MOBILE = !MSStream && [ANDROID_REGEX, IOS_REGEX].some(regex => regex.test(platform) || regex.test(userAgent));
export const IS_FIREFOX = FIREFOX_REGEX.test(platform) || FIREFOX_REGEX.test(userAgent);
export const DEFAULT_QUALITY = IS_MOBILE ? 'low' : 'medium';
