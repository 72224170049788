import { TOOLTIPS } from '../actions/names';

const reducer = (state, { type }) => {
  switch (type) {
    case TOOLTIPS:
      return !state;
    default:
      return !!state;
  }
};

export default reducer;
