import React from 'react';
import { I18n, Translate } from 'react-i18nify';
import NavHome from './Home/NavHome';

const { REACT_APP_SURVEY_URL } = process.env;

const ThankYou = () => (
  <div>
    <NavHome />

    <section className="corpo">
      <div className="u-center-center thank-you-txt text-center">
        <img src="/images/ok.png" className="img-fluid" alt="" />
        <h3 className="text-center">
          <Translate value="THANK_YOU" />
        </h3>
        <p className="text-center">
          <Translate value="SUBMIT.SUCCESS" />
        </p>

        <img src="/images/alka-logo.png" className="img-fluid alka-logo" alt="" />

        <a href={I18n.t(('THANK_YOU_RETURN_URL'))} className="u-center-horizontal d-block">
          <button type="button" className="btn confirm-button uppercase m-t-20 no-hover">
            <Translate value="THANK_YOU_RETURN_MESSAGE" />
          </button>
        </a>
        {
          REACT_APP_SURVEY_URL && (
            <a
              href={REACT_APP_SURVEY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="u-center-horizontal d-block"
            >
              <button type="button" className="btn confirm-button uppercase m-t-20 no-hover">
                <Translate value="TAKE_OUR_SURVEY" />
              </button>
            </a>
          )
        }
      </div>
    </section>
  </div>
);

export default ThankYou;
