import * as Sentry from '@sentry/browser';
import { CLEAR, SET_CONFIG, SET_QUALITY } from '../actions/names';

const initialState = {};

const reducer = (state = initialState, { type, config = {}, quality }) => {
  switch (type) {
    case SET_CONFIG:
      try {
        return JSON.parse(JSON.stringify(config));
      } catch (error) {
        Sentry.captureException(error);
        return state;
      }
    case SET_QUALITY:
      return Object.assign({}, state, { quality });
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
