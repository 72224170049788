import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorItem from './ColorItem';

class Colors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePickerKey: '',
    };
  }

  togglePicker(key) {
    const { activePickerKey } = this.state;
    this.setState({ activePickerKey: activePickerKey === key ? '' : key });
  }

  render() {
    const { activePickerKey = [] } = this.state;
    const { parts = [], onChangeColor, tooltips } = this.props;

    return (
      <div>
        {
          parts.map((part, index) => (
            <ColorItem
              tooltips={tooltips}
              itemIndex={index}
              key={part.key}
              part={part}
              open={activePickerKey === part.key}
              onChangeColor={onChangeColor}
              onTogglePicker={key => this.togglePicker(key)}
            />
          ))
        }
      </div>
    );
  }
}

Colors.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeColor: PropTypes.func.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default Colors;
