import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import {
  AvForm,
  AvInput,
  AvFeedback,
  AvGroup,
} from 'availity-reactstrap-validation';
import { Translate, I18n } from 'react-i18nify';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import { getAvailableColors, getFluoColors } from '../helper/colors';

const FLUO_COLORS = getFluoColors()
  .sort((a, b) => (a.priority > b.priority ? 1 : -1));

const ALL_COLORS = getAvailableColors().concat(FLUO_COLORS)
  .sort((a, b) => (a.priority === undefined || a.priority > b.priority ? 1 : -1));

const MOST_USED_COLORS = ALL_COLORS.filter(({ mostUsed }) => mostUsed)
  .sort((a, b) => (a.priority > b.priority ? 1 : -1));

const MOST_USED_COLORS_PLUS_FLUOS = MOST_USED_COLORS.concat(FLUO_COLORS);

const matchColorIgnoreCase = (value = '') => ({ name = '', pantone = '' }) => {
  const lcValue = value.toLowerCase();
  return lcValue === name.toLowerCase() || lcValue === pantone.toLowerCase();
};

const validate = debounce((value, ctx, input, cb) => {
  if (!value) return cb(true);
  const valid = ALL_COLORS.some(matchColorIgnoreCase(value));
  return cb(valid);
}, 300);

class PantonePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllColors: false,
      showMostUsedPlusFluos: false,
    };
    this.formRef = React.createRef();
  }

  matchColor(value) {
    const { onChange } = this.props;
    const { showAllColors } = this.state;
    const color = ALL_COLORS.find(matchColorIgnoreCase(value));
    if (!color) return;
    if (!showAllColors && !MOST_USED_COLORS.find(matchColorIgnoreCase(value))) {
      this.setState({ showAllColors: true });
    }
    onChange(color);
    if (this.formRef.current) {
      this.formRef.current.reset();
    }
  }

  render() {
    const {
      color, onChange, itemIndex, tooltips,
    } = this.props;
    const {
      showAllColors,
      showMostUsedPlusFluos,
    } = this.state;
    let colors;
    if (showAllColors) {
      colors = ALL_COLORS;
    } else if (showMostUsedPlusFluos) {
      colors = MOST_USED_COLORS_PLUS_FLUOS;
    } else {
      colors = MOST_USED_COLORS;
    }

    return (
      <div className="pantone-picker-cont">
        <AvForm
          autoComplete="off"
          ref={this.formRef}
          className="text-form"
          onValidSubmit={(event, values) => this.matchColor(values.search)}
        >
          <AvGroup row>
            <Col xs={10} sm={10} id={`search-pantone-${itemIndex}`}>
              <AvInput type="text" name="search" validate={{ async: validate }} placeholder={I18n.t('INSERT_PANTONE')} autoComplete="off" />
              <AvFeedback><Translate value="PANTONE_NOT_FOUND" /></AvFeedback>
            </Col>
            <Col xs={2} sm={2}>
              <Button id={`start-search-pantone-${itemIndex}`}>
                <i className="fas fa-chevron-circle-right" role="presentation" />
              </Button>
            </Col>
          </AvGroup>
        </AvForm>

        <div className="color-picker-cont">
          {
            (showAllColors || showMostUsedPlusFluos) && (
              <div>
                <span id="show-most-used-colors">
                  <div
                    className="first-div"
                    onClick={() => this.setState({
                      showAllColors: false,
                      showMostUsedPlusFluos: false,
                    })}
                    role="presentation"
                  >
                    <span>
                      <div className="second-div text-center p-t-5">
                        <i className="fas fa-caret-up" role="presentation" />
                      </div>
                    </span>
                    <div className="picker-pantone text-center uppercase">
                      <Translate value="LESS_COLORS" />
                    </div>
                  </div>
                </span>
                {
                  tooltips && (
                    <UncontrolledTooltip placement="bottom" target="show-most-used-colors">
                      <Translate value="TOOLTIPS.MOST_USED_COLORS" />
                    </UncontrolledTooltip>
                  )
                }
              </div>
            )
          }
          {
            colors.map(({
              name,
              hex,
              pantone,
              showFullName,
            }, pantoneIndex) => (
              <div key={pantone}>
                <span id={`select-pantone-${itemIndex}-${pantoneIndex}`}>
                  <div
                    className={`first-div ${color && (color.pantone === pantone || color.hex === hex) ? 'active' : ''}`}
                    onClick={() => onChange({ name, hex, pantone })}
                    role="presentation"
                  >
                    <span>
                      <div className="second-div" style={{ boxShadow: `${hex} 0px 0px 0px 20px inset` }} />
                    </span>
                    <div className={`picker-pantone${showFullName ? ' picker-pantone-initial-of' : ''}`}>
                      {pantone}
                    </div>
                  </div>
                </span>
                {
                  tooltips && (
                    <UncontrolledTooltip placement="bottom" target={`select-pantone-${itemIndex}-${pantoneIndex}`}>
                      <Translate value="TOOLTIPS.SELECT_PANTONE" />
                      {name}
                      {pantone ? ` (${pantone})` : ''}
                    </UncontrolledTooltip>
                  )
                }
              </div>
            ))
          }
          {
            (!showMostUsedPlusFluos && !showAllColors) && (
              <div>
                <span id="show-fluo-colors">
                  <div
                    className="first-div"
                    onClick={() => this.setState({ showMostUsedPlusFluos: true })}
                    role="presentation"
                  >
                    <span>
                      <div className="second-div text-center p-t-5">
                        <i className="fas fa-caret-down" role="presentation" />
                      </div>
                    </span>
                    <div className="picker-pantone text-center uppercase">
                      <Translate value="FLUO" />
                    </div>
                  </div>
                </span>
                {
                  tooltips && (
                    <UncontrolledTooltip placement="bottom" target="show-fluo-colors">
                      <Translate value="TOOLTIPS.FLUO_COLORS" />
                    </UncontrolledTooltip>
                  )
                }
              </div>
            )
          }
          {
            !showAllColors && (
              <div>
                <span id="show-all-pantones">
                  <div
                    className="first-div"
                    onClick={() => this.setState({ showAllColors: true })}
                    role="presentation"
                  >
                    <span>
                      <div className="second-div text-center p-t-5">
                        <i className="fas fa-caret-down" role="presentation" />
                      </div>
                    </span>
                    <div className="picker-pantone text-center uppercase">
                      <Translate value="ALL_COLORS" />
                    </div>
                  </div>
                </span>
                {
                  tooltips && (
                    <UncontrolledTooltip placement="bottom" target="show-all-pantones">
                      <Translate value="TOOLTIPS.ALL_COLORS" />
                    </UncontrolledTooltip>
                  )
                }
              </div>
            )
          }
        </div>
        {
          tooltips && [
            <UncontrolledTooltip key={0} placement="bottom" target={`search-pantone-${itemIndex}`}>
              <Translate value="TOOLTIPS.SEARCH_PANTONE" />
            </UncontrolledTooltip>,
            <UncontrolledTooltip key={1} placement="bottom" target={`start-search-pantone-${itemIndex}`}>
              <Translate value="TOOLTIPS.START_SEARCH" />
            </UncontrolledTooltip>,
          ]
        }
      </div>
    );
  }
}
PantonePicker.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  color: PropTypes.shape({
    pantone: PropTypes.string,
    hex: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default PantonePicker;
