/** @module TShirtConfigurator */

import {EventEmitter} from 'events';
import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
import Model from './Model';
import { DEFAULT_DIRECTIONAL_LIGHTS } from './constants';

/**
 * An RGB Color specified as a number. E.g. white: 0xffffff, red: 0xff0000.
 * @typedef {number} Color
 */

/**
 * @typedef ModelConfigurationPart
 * @type {Object}
 * @property {string} key The key of the part.
 * @property {Color} color The default color.
 * @property {string} url The url of the alphamap texture for this part.
 */

/**
 * @typedef ModelConfigurationAccessory
 * @type {Object}
 * @property {string} key The key of the accessory.
 * @property {string[]} url The array of the possible texture urls for this accessory.
 */

/**
 * @typedef ModelConfigurationItem
 * @type {Object}
 * @property {number} angle The rotation angle.
 * @property {number} scale The scaling factor.
 * @property {number[]} point The point vector3.
 * @property {number[]} uv The uv vector2.
 * @property {string} type The type of object 'TEXT' or 'IMAGE'.
 * @property {string} url (for images) The URL of the image (as a data URL).
 * @property {number} color (for text) The text color.
 * @property {string} text (for text) The text string.
 * @property {string} font (for text) The text font.
 */

/**
 * @typedef ModelConfigurationGroups
 * @type {Object}
 * @property {ModelConfigurationItem[]} items The array of items.
 * @property {string[]} objects The array of object names.
 */

/**
 * @typedef ModelConfiguration
 * @type {Object}
 * @property {string} url The URL of the GLTF file.
 * @property {ModelConfigurationAccessory[]} accessories The array of accessories.
 * @property {ModelConfigurationGroup[]} groups The array of element groups.
 * @property {string[]} overlays The array of overlay URLs.
 * @property {ModelConfigurationPart[]} parts The array of parts.
 */

/**
 * The TShirtConfigurator class.
 */
export default class TShirtViewer extends EventEmitter {
    /**
     * Creates a new instance of TShirtConfigurator.
     * @param {HTMLElement} element The HTML element that will contain the TShirt configurator.
     * @param {Object} config Model config.
     */
    constructor(element, config) {
        super();
        const { directionalLights = DEFAULT_DIRECTIONAL_LIGHTS } = config || {};

        this.render = this.render.bind(this);

        this.element = element;

        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({antialias: true, preserveDrawingBuffer: true});
        // this.renderer.gammaOutput = true;
        // this.renderer.gammaFactor = 2.2;
        this.camera = new THREE.PerspectiveCamera(40, 1 /* to be calculated by resize() */, 0.25, 1000);
        this.scene.add(this.camera);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableKeys = false;
        this.controls.enablePan = false;
        this.controls.enableZoom = true;
        this.controls.minPolarAngle = Math.PI / 4;
        this.controls.maxPolarAngle = Math.PI * 3 / 4;
        this.controls.addEventListener('change', this.render);

        this.camera.position.set(0, 0, 10);
        this.scene.background = new THREE.Color(0xefefef);
        this.scene.add(new THREE.AmbientLight(0xffffff, .9));
        const light1 = new THREE.DirectionalLight(0xffffff, directionalLights[0].intensity);
        light1.position.set(2, 3, 3);
        this.camera.add(light1);
        const light2 = new THREE.DirectionalLight(0xffffff, directionalLights[1].intensity);
        light2.position.set(-1, 4, 30);
        this.camera.add(light2);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        element.appendChild(this.renderer.domElement);
        this.resize();
    }

    /**
     * Sets the camera position and aspect ratio depending on the current element size.
     * Must be called when the element changed size (e.g. on browser resize).
     */
    resize() {
        const {height, width} = this.element.getBoundingClientRect();
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
        this.updateCamera();
        this.render();
    }

    /**
     * Renders the current scene.
     */
    render() {
        this.model && this.model.render();
        this.renderer.render(this.scene, this.camera);
    }

    /**
     * Removes the current loaded model.
     */
    clear() {
        if (this.model) {
            this.scene.remove(this.model.scene);
            delete this.model;
            this.render();
        }
    }

    /**
     * Disposes the current renderer, this object is no longer usable.
     */
    dispose() {
        if (!this.renderer) {
            // Already disposed
            return;
        }
        if (this.model) {
            this.scene.remove(this.model.scene);
            this.model.dispose();
            delete this.model;
        }
        this.controls.dispose();
        this.element.removeChild(this.renderer.domElement);
        this.renderer.dispose();
        delete this.renderer;
    }

    /**
     * Load a new model.
     * @param {ModelConfiguration} modelOptions The model configuration.
     * @return {Promise}
     */
    async loadModelAsync(modelOptions) {
        this.clear();

        this.model = new Model(modelOptions, this.renderer);
        await this.model.loadAsync();

        const obj = this.model.scene;
        const bbox = new THREE.Box3().setFromObject(obj);
        this.modelSize = bbox.getSize(new THREE.Vector3()).length();
        const center = bbox.getCenter(new THREE.Vector3());
        obj.position.x = -center.x;
        obj.position.y = -center.y;
        obj.position.z = -center.z;
        // this.scene.add(new THREE.BoxHelper(obj, 0xff0000));
        this.scene.add(obj);
        const { cameraOptions } = modelOptions || {};
        this.updateCamera(cameraOptions);

        if (this.debug) {
            this.model.groups.forEach((group, index) => {
                const geometry = new THREE.PlaneGeometry();
                const material = new THREE.MeshBasicMaterial({map: group.renderTarget.texture, side: THREE.DoubleSide});
                const plane = new THREE.Mesh(geometry, material);
                plane.position.x = -3.5 + index * 1.05;
                plane.position.y = -2.5;
                plane.position.z = 1.5;
                this.scene.add(plane);
            });
        }

        setImmediate(() => {
            this.emit('modelLoaded', modelOptions);
            this.render();
        });
    }

    /**
     * Returns the current model.
     * @return {ModelConfiguration}
     */
    exportModel() {
        return this.model.export();
    }

    /**
     * Takes a PNG screenshot.
     * @return {string} The output PNG rendered as a data URL.
     */
    exportPng() {
        return this.renderer.domElement.toDataURL('image/png');
    }

    /**
     * @private
     */
    updateCamera(cameraOptions) {
        if (!this.modelSize) {
            return;
        }
        const { height, width } = this.element.getBoundingClientRect();
        const { maxDistance = 1.5, minDistance = 0.62, cameraRatio = 1 } = cameraOptions || {};
        const aspectRatio = Math.min(width / height, 1);
        const distance = this.modelSize * (3 - aspectRatio * 2) * cameraRatio;
        this.controls.minDistance = distance * minDistance;
        this.controls.maxDistance = distance * maxDistance;
        this.camera.position.normalize();
        this.camera.position.multiplyScalar(distance);
        this.controls.update();
    }
}
