import {
  getFirstVariant,
  getBaseVariant,
  getSecondVariant,
  getThirdVariant,
  getFourthVariant,
  getFifthVariant,
  getSixthVariant,
  getSeventhVariant,
  getEighthVariant,
  getNinthVariant,
  SNAPLINE_DELTA,
} from './AXSHARED';

export default () => {
  const prefix = '/models';
  const variants = [
    getBaseVariant('/images/AX513/AX513.png', [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
    getSixthVariant('/images/AX513/AX513_sixth_variant.png'),
    getNinthVariant('/images/AX513/AX513_ninth_variant.png'),
    getSeventhVariant('/images/AX513/AX513_seventh_variant.png'),
    getFirstVariant('/images/AX513/AX513_variant.png', [1, 2, 3, 4]),
    getThirdVariant('/images/AX513/AX513_third_variant.png'),
    getEighthVariant('/images/AX513/AX513_eighth_variant.png'),
    getFifthVariant('/images/AX513/AX513_fifth_variant.png'),
    getFourthVariant('/images/AX513/AX513_fourth_variant.png'),
    getSecondVariant('/images/AX513/AX513_second_variant.png'),
  ];

  const config = {
    url: `${prefix}/AX513/TShirt_AX513.gltf`,
    parts: variants[0].parts,
    overlays: variants[0].overlays,
    accessories: variants[0].accessories,
    hasNamedAccessories: true,
    groups: [
      {
        objects: [
          'TShirt_AX_Fronte_DX',
          'TShirt_AX_Fronte_SX',
          'TShirt_AX_ZipFake_Busto',
        ],
        snaplines: [
          { x: 0.25, delta: SNAPLINE_DELTA },
        ],
      },
      {
        objects: [
          'TShirt_AX_Fianco_DX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Fianco_SX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Retro',
        ],
        snaplines: [
          { x: 0.77, delta: SNAPLINE_DELTA },
        ],
      },
      {
        objects: [
          'TShirt_AX_Cuffia_DX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Cuffia_SX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Collo',
          'TShirt_AX_ZipFake_Collo',
        ],
      },
      {
        objects: [
          'TShirt_AX_Tasca',
        ],
        snaplines: [
          { x: 0.28, delta: 0.02 },
        ],
      },
      {
        objects: [
          'TShirt_AX_RiportiBusto',
        ],
      },
      {
        objects: [
          'TShirt_AX_RiportiManiche',
        ],
      },
      {
        drawable: false,
        transparent: true,
        objects: [
          'TShirt_AX_RibattuteManiche',
          'TShirt_AX_RibattuteBusto_O',
          'TShirt_AX_RibattuteBusto_V',
        ],
      },
      {
        drawable: false,
        objects: [
          'TShirt_AX_Zip',
        ],
      },
      {
        drawable: false,
        objects: [
          'TShirt_AX_TascaElastico',
        ],
      },
    ],
    optionals: [
      {
        key: 'Tasca',
        objects: [
          'TShirt_AX_TascaZip',
          'TShirt_AX_TascaZipFettuccia',
          'TShirt_AX_TascaZipCursore',
        ],
      },
    ],
  };
  return { config, variants };
};
