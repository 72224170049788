import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Label, Col, UncontrolledTooltip } from 'reactstrap';

const Accessory = ({
  accessories,
  hasNamedAccessories,
  onChangeAccessory,
  optionals,
  onChangeOptional,
  tooltips,
}) => (
  <div>
    <h4><Translate value="SELECT_ACCESSORY" /></h4>
    <hr />
    {
      accessories.filter(({ hidden }) => !hidden)
        .map(({ current = 0, key, url: urls = [] }, accessoryIndex) => (
          <AvForm key={key}>
            <AvGroup row>
              <Label sm={2} for={key}>{key}</Label>
              <Col sm={10}>
                <AvField
                  id={`select-accessory-${accessoryIndex}`}
                  name={key}
                  onChange={ev => onChangeAccessory(key, Number(ev.target.value))}
                  type="select"
                  value={current}
                >
                  {
                  urls.map((url, index) => (
                    <option key={url} value={index}>
                      {
                        hasNamedAccessories ? I18n.t(url.split('/')
                          .pop()
                          .split('.')
                          .shift()
                          .split('-')
                          .slice(1)
                          .join(' ')) : `${I18n.t('OPTION')} ${index + 1}`
                      }
                    </option>
                  ))
                }
                </AvField>
              </Col>
            </AvGroup>
            {
            tooltips && (
              <UncontrolledTooltip placement="bottom" target={`select-accessory-${accessoryIndex}`}>
                <Translate value="TOOLTIPS.SELECT_ACCESSORY" />
                { key }
                {'.'}
              </UncontrolledTooltip>
            )
          }
          </AvForm>
        ))
    }
    {
      optionals.map(({ key, visible = true }, optionalIndex) => (
        <AvForm key={key}>
          <AvGroup row>
            <Label sm={2} for={key}>{key}</Label>
            <Col sm={10}>
              <AvField
                id={`select-optional-${optionalIndex}`}
                name={key}
                onChange={ev => onChangeOptional(key, ev.target.value === 'true')}
                type="select"
                value={(!!visible).toString()}
              >
                <option key="true" value="true">
                  {`${I18n.t('YES')}`}
                </option>
                <option key="false" value="false">
                  {`${I18n.t('NO')}`}
                </option>
              </AvField>
            </Col>
          </AvGroup>
          {
            tooltips && (
              <UncontrolledTooltip placement="bottom" target={`select-optional-${optionalIndex}`}>
                <Translate value="TOOLTIPS.SELECT_ACCESSORY" />
                { key }
                {'.'}
              </UncontrolledTooltip>
            )
          }
        </AvForm>
      ))
    }
  </div>
);

Accessory.propTypes = {
  accessories: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  // eslint-disable-next-line react/require-default-props
  hasNamedAccessories: PropTypes.bool,
  optionals: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  onChangeAccessory: PropTypes.func.isRequired,
  onChangeOptional: PropTypes.func.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default Accessory;
