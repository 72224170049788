import { SET_CONFIG, SET_QUALITY } from './names';

export const setConfig = config => ({
  type: SET_CONFIG,
  config,
});

export const setQuality = quality => ({
  type: SET_QUALITY,
  quality,
});
