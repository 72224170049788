import React from 'react';
import NavHome from './NavHome';
import ListModels from './ListModels';

const Home = () => (
  <div>
    <NavHome />

    <section className="corpo">
      <div className="p-30-16">
        <ListModels />
      </div>
    </section>
  </div>
);

export default Home;
