import { CLEAR, SET_MODEL_NAME } from '../actions/names';

const initialState = '';

const reducer = (state = initialState, { type, modelName = '' }) => {
  switch (type) {
    case SET_MODEL_NAME:
      return modelName;
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
