import axios from 'axios';

export const get = (id) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_API_BACKEND_API_KEY,
    },
    params: {
      id,
    },
  };
  const URL = `${process.env.REACT_APP_API_BACKEND_URL}/quote`;
  return axios.get(URL, options);
};
