import * as THREE from 'three';
import ImageDrawer from './drawers/ImageDrawer';
import TextDrawer from './drawers/TextDrawer';
import buttonImages from './buttonImages';
import {BUTTON_SIZE, BUTTONS, OBJECT_TYPES} from './constants';
import createItem from './items/createItem';

export default class Group {
    constructor(objects, width, height, items, snaplines, drawable = true, transparent) {
        this.renderTarget = new THREE.WebGLRenderTarget(width, height);
        this.objects = objects;
        this.RENDER_TARGET_WIDTH = width
        this.RENDER_TARGET_HEIGHT = height
        this._canvas = document.createElement('canvas');
        this._canvas.width = width;
        this._canvas.height = height;
        this._context = this._canvas.getContext('2d');
        this._canvasTexture = new THREE.Texture(this._canvas);
        this._canvasTexture.needsUpdate = true;

        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide, transparent: !transparent, depthWrite: false, map: this._canvasTexture,
        });
        this.mesh = new THREE.Mesh(new THREE.PlaneGeometry(), material);

        this._drawers = {
            [OBJECT_TYPES.IMAGE]: new ImageDrawer(this._context),
            [OBJECT_TYPES.TEXT]: new TextDrawer(this._context),
        };

        this.items = [];
        if (items && items.length) {
            this.items = items.map(createItem);
        }

        this.snaplines = snaplines || [];
        this.drawable = drawable;
    }

    dispose() {
        this.renderTarget.dispose();
        delete this.renderTarget;
        this._canvasTexture.dispose();
        delete this._canvasTexture;
        delete this.objects;
        delete this._context;
        delete this._canvas;
    }

    transformPoints(points, transformation) {
        return points
            .map((p) => new THREE.Vector3(p[0], p[1], 0))
            .map((v3) => v3.applyMatrix4(transformation))
            .map((v3) => [v3.x / this._canvas.width, v3.y / this._canvas.height]);
    }

    transformButtonPoints(x, y, transformation) {
        const inputPoints = [
            [x, y],
            [x, y + BUTTON_SIZE],
            [x + BUTTON_SIZE, y + BUTTON_SIZE],
            [x + BUTTON_SIZE, y],
        ];
        return this.transformPoints(inputPoints, transformation);
    }

    draw() {
        if (!this._context) {
            return;
        }
        this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);

        this.items.forEach((item) => {
            const {selected, type, uv} = item;

            this._context.save();

            const transformation = new THREE.Matrix4();
            transformation.identity();

            this._context.translate(this._canvas.width * uv.x, this._canvas.height * (1 - uv.y));
            const transl = new THREE.Matrix4();
            transl.makeTranslation(this._canvas.width * uv.x, this._canvas.height * (1 - uv.y), 0);
            transformation.multiply(transl);

            this._context.rotate(item.angle);
            const rot = new THREE.Matrix4();
            rot.makeRotationZ(item.angle);
            transformation.multiply(rot);

            this._context.save();
            const size = this._drawers[type].draw(item);
            item.setEffectiveSize(size.width / this.RENDER_TARGET_WIDTH, size.height / this.RENDER_TARGET_HEIGHT);
            this._context.restore();

            if (selected) {
                this._context.lineWidth = 2;
                this._context.strokeStyle = '#eeeeee';
                this._context.strokeRect(-size.width / 2, -size.height / 2, size.width, size.height);
                if (!item.pinned) {
                    this._context.drawImage(buttonImages.img, buttonImages[BUTTONS.RESIZE].x, buttonImages[BUTTONS.RESIZE].y,
                        buttonImages.width, buttonImages.height,
                        size.width / 2, size.height / 2, BUTTON_SIZE, BUTTON_SIZE);
                    this._context.drawImage(buttonImages.img, buttonImages[BUTTONS.ROTATE].x, buttonImages[BUTTONS.ROTATE].y,
                        buttonImages.width, buttonImages.height,
                        size.width / 2, -size.height / 2 - BUTTON_SIZE, BUTTON_SIZE, BUTTON_SIZE);
                    /* this._context.drawImage(buttonImages.img, buttonImages[BUTTONS.TRASH].x, buttonImages[BUTTONS.TRASH].y,
                        buttonImages.width, buttonImages.height,
                        -size.width / 2 - BUTTON_SIZE, size.height / 2, BUTTON_SIZE, BUTTON_SIZE);*/
                }

                item.selectionContours = {
                    [BUTTONS.RESIZE]: this.transformButtonPoints(size.width / 2, size.height / 2, transformation),
                    [BUTTONS.ROTATE]: this.transformButtonPoints(size.width / 2, -size.height / 2 - BUTTON_SIZE, transformation),
                    // [BUTTONS.TRASH]: this.transformButtonPoints(-size.width / 2 - BUTTON_SIZE, size.height / 2, transformation),
                };
            }

            this._context.restore();

            const inputPoints = [
                [size.width / 2, -size.height / 2],
                [-size.width / 2, -size.height / 2],
                [-size.width / 2, size.height / 2],
                [size.width / 2, size.height / 2],
            ];
            item.contour = this.transformPoints(inputPoints, transformation);
        });

        this._canvasTexture.needsUpdate = true;
    }

    addItem(item) {
        this.items.push(item);
        this.draw();
    }

    removeItem(item) {
        const idx = this.items.indexOf(item);
        if (idx < 0) {
            throw new Error('Item not found in group.');
        }
        this.items.splice(idx, 1);
        this.draw();
    }
}
