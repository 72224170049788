/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import pantones from './pantones.json';
import fluos from './fluos.json';

export function number2Color(color) {
  let hex = Number(color).toString(16);
  hex = '000000'.substr(0, 6 - hex.length) + hex;
  return `#${hex}`;
}

export function color2Number(color) {
  return parseInt(color.replace(/#/g, ''), 16);
}

export function getAvailableColors() {
  return pantones;
}

export function getFluoColors() {
  return fluos;
}

export function addPantonesToConfig(config = {}) {
  const newConfig = { ...config };
  const { groups = [] } = newConfig;
  newConfig.groups = groups.map((group = {}) => {
    const updatedGroup = { ...group };
    const { items = [] } = updatedGroup;
    updatedGroup.items = items.map((item = {}) => {
      const updatedItem = { ...item };
      const { color: itemColor } = updatedItem;
      const foundPantone = pantones.find(({ hex }) => hex === number2Color(itemColor));
      if (foundPantone) {
        const { name: pantoneName, pantone } = foundPantone;
        updatedItem.pantone = pantone;
        updatedItem.pantoneName = pantoneName;
      }
      return updatedItem;
    });
    return updatedGroup;
  });
  return newConfig;
}

/*
  http://alienryderflex.com/hsp.html
*/
export function isLight(hex) {
  const r = hex >> 16;
  const g = hex >> 8 & 255;
  const b = hex & 255;

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp > 127.5;
}
