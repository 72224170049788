import React from 'react';
import {
  Navbar,
  NavbarBrand,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toggleTooltips } from '../../actions/tooltips';
import { setQuality } from '../../actions/config';
import { version } from '../../../package.json';

const { REACT_APP_ENABLE_RETURN_URL } = process.env;
const enableReturnUrl = REACT_APP_ENABLE_RETURN_URL === 'true';

const NavConfiguration = ({
  history,
  model,
  modelName,
  dispatchToggleTooltips,
  tooltips,
  // config,
  // setQuality,
  // loadModel,
  variant,
}) => {
  const confirmBack = () => {
    const customUI = ({ onClose }) => (
      <div className="custom-ui p-16">
        <h1>
          <Translate value="ARE_YOU_SURE" />
        </h1>
        <p>
          <Translate value="BACK_TO_MODELS_MSG" />
        </p>
        <button type="button" className="btn confirm-button uppercase m-r-10" onClick={onClose}>
          <Translate value="NO" />
        </button>
        {
          enableReturnUrl ? (
            <a href={I18n.t('THANK_YOU_RETURN_URL')} className="btn confirm-button uppercase no-hover" onClick={onClose}>
              <Translate value="YES" />
            </a>
          ) : (
            <button type="button" className="btn confirm-button uppercase" onClick={() => { history.push('/'); onClose(); }}>
              <Translate value="YES" />
            </button>
          )
        }
      </div>
    );
    customUI.propTypes = {
      onClose: PropTypes.func.isRequired,
    };
    confirmAlert({ customUI });
  };

  // const onSelectQuality = (ev) => {
  //   setQuality(ev.target.value);
  //   loadModel();
  // };

  return (
    <div>
      <Navbar color="light" light expand="lg">
        <NavbarBrand href={enableReturnUrl ? I18n.t('THANK_YOU_RETURN_URL') : '/'}>
          <img src="/images/alka-logo.png" alt="Alka Sport" className="img-fluid" />
        </NavbarBrand>
        <span style={{ lineHeight: '3rem' }}>{`v.${version}`}</span>
        {/* <select */}
        {/*   className="m-l-10" */}
        {/*   value={config.quality || 'medium'} */}
        {/*   onChange={onSelectQuality} */}
        {/* > */}
        {/*   <option value="mobile">Mobile</option> */}
        {/*   <option value="low">Bassa Qualità</option> */}
        {/*   <option value="medium">Qualità Media</option> */}
        {/*   <option value="high">Alta Qualità</option> */}
        {/* </select> */}
        <div className="content-elements">
          <div className="ml-auto">
            {
              variant && (
                <Link to="/customer">
                  <button type="button" className="btn m-l-10" id="checkout-button">
                    <Translate value="CHECKOUT" />
                  </button>
                </Link>
              )
            }
            <button onClick={confirmBack} type="button" className="btn uppercase p-r-i-30 p-l-i-30">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-570 871 51.2 22" className="arrow-back">
                <path className="arrow" d="M-558.3 872.7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7 0 .3.2.6.4.8l9.9 9.9c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-8.3-8.3h46.7c.6 0 1-.4 1-1s-.4-1-1-1h-46.8l8.4-8.3z" />
              </svg>
              <Translate value="BACK" />
            </button>
            <button onClick={() => dispatchToggleTooltips()} type="button" className="tooltips-button">
              <u>
                <Translate value={`TOOLTIPS.${tooltips ? 'HIDE' : 'SHOW'}`} />
              </u>
            </button>
          </div>
          <br />
          {
            tooltips && variant && (
              <UncontrolledTooltip placement="bottom" target="checkout-button">
                <Translate value="TOOLTIPS.CHECKOUT" />
              </UncontrolledTooltip>
            )
          }
        </div>
      </Navbar>
      <div className="cont-lt-menu">
        <h3>
          <Translate value="MODEL_SELECTED" />
          :
          <span className="model-name">
            {modelName || model}
          </span>
        </h3>
      </div>
    </div>
  );
};

NavConfiguration.propTypes = {
  dispatchToggleTooltips: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  model: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  modelName: PropTypes.string,
  tooltips: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  model: state.model,
  modelName: state.modelName,
  tooltips: state.tooltips,
  config: state.config,
  variant: state.variant,
});

const mapDispatchToProps = {
  dispatchToggleTooltips: () => toggleTooltips(),
  setQuality,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavConfiguration));
