import {
  getFirstVariant,
  getBaseVariant,
  getSecondVariant,
  getThirdVariant,
  getFourthVariant,
  getFifthVariant,
  getSixthVariant,
  getSeventhVariant,
  getEighthVariant,
  getNinthVariant,
  SNAPLINE_DELTA,
} from './AXSHARED';

export default () => {
  const prefix = '/models';
  const variants = [
    getBaseVariant('/images/AX512/AX512.png'),
    getSixthVariant('/images/AX512/AX512_sixth_variant.png'),
    getNinthVariant('/images/AX512/AX512_ninth_variant.png'),
    getSeventhVariant('/images/AX512/AX512_seventh_variant.png'),
    getFirstVariant('/images/AX512/AX512_variant.png'),
    getThirdVariant('/images/AX512/AX512_third_variant.png'),
    getEighthVariant('/images/AX512/AX512_eighth_variant.png'),
    getFifthVariant('/images/AX512/AX512_fifth_variant.png'),
    getFourthVariant('/images/AX512/AX512_fourth_variant.png'),
    getSecondVariant('/images/AX512/AX512_second_variant.png'),
  ];

  const config = {
    quality: 'low',
    url: `${prefix}/AX512/TShirt_AX512.gltf`,
    parts: variants[0].parts,
    overlays: variants[0].overlays,
    accessories: variants[0].accessories,
    hasNamedAccessories: true,
    groups: [
      {
        objects: [
          'TShirt_AX_Fronte_DX',
          'TShirt_AX_Fronte_SX',
          'TShirt_AX_ZipFake_Busto',
        ],
        snaplines: [
          { x: 0.25, delta: SNAPLINE_DELTA },
        ],
      },
      {
        objects: [
          'TShirt_AX_Fianco_DX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Fianco_SX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Retro',
        ],
        snaplines: [
          { x: 0.77, delta: SNAPLINE_DELTA },
        ],
      },
      {
        objects: [
          'TShirt_AX_Cuffia_DX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Cuffia_SX',
        ],
      },
      {
        objects: [
          'TShirt_AX_Collo',
          'TShirt_AX_ZipFake_Collo',
        ],
      },
      {
        objects: [
          'TShirt_AX_Tasca',
        ],
        snaplines: [
          { x: 0.28, delta: 0.02 },
        ],
      },
      {
        drawable: false,
        objects: [
          'TShirt_AX_Zip',
        ],
      },
      {
        drawable: false,
        objects: [
          'TShirt_AX_TascaElastico',
        ],
      },
    ],
    optionals: [
      {
        key: 'Tasca',
        objects: [
          'TShirt_AX_TascaZip',
          'TShirt_AX_TascaZipFettuccia',
          'TShirt_AX_TascaZipCursore',
        ],
      },
    ],
  };
  return { config, variants };
};
