import image from '../res/buttons.png';
import {BUTTONS} from './constants';

const element = document.createElement('img');
element.src = image;

export default {
    img: element,
    height: 64,
    width: 64,
    [BUTTONS.RESIZE]: {x: 64, y: 0},
    [BUTTONS.ROTATE]: {x: 0, y: 0},
    [BUTTONS.TRASH]: {x: 128, y: 0},
};
