import React from 'react';
import { Translate } from 'react-i18nify';
import NavHome from './Home/NavHome';

const NotFound = () => (
  <div>
    <NavHome />

    <section className="corpo">
      <div className="u-center-center">
        <h1 className="not-found-text u-center-horizontal-inner">404</h1>
        <span className="not-found-text u-center-horizontal-inner">
          <Translate value="NOT_FOUND_MESSAGE" />
        </span>
      </div>
    </section>
  </div>
);

export default NotFound;
