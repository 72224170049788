import _ from 'lodash';
import pointInPolygon from 'robust-point-in-polygon';
import * as THREE from 'three';
import GLTFLoader from 'three-gltf-loader';
import Group from './Group';
import ImageItem from './items/ImageItem';
import TextItem from './items/TextItem';
import prepareDataAsync from './utils/prepareDataAsync';
import { getRenderTargetHeight, getRenderTargetWidth } from './constants';

const raycaster = new THREE.Raycaster();

export default class Model {
    constructor(opts, renderer) {
        this.opts = _.cloneDeep(opts);
        this.renderer = renderer;

        const { quality } = this.opts;
        this.RENDER_TARGET_WIDTH = getRenderTargetWidth(quality);
        this.RENDER_TARGET_HEIGHT = getRenderTargetHeight(quality);
    }

    async loadAsync() {
        await prepareDataAsync(this.opts);
        return new Promise((resolve, reject) => {
            const manager = new THREE.LoadingManager();

            manager.onLoad = () => {
                this.init();
                const loader = new GLTFLoader();
                loader.load(this.opts.url, (gltf) => {
                    this.scene = gltf.scene;
                    this.initGroups();
                    resolve();
                });
            };
            manager.onError = () => reject();

            const textureLoader = new THREE.TextureLoader(manager);
            this.parts = this.opts.parts.map((part) => Object.assign({}, part, {
                texture: textureLoader.load(part.url),
                color: _.isUndefined(part.color) ? 0xffffff : part.color,
            }));
            this.overlays = this.opts.overlays.map((overlay) => ({
                texture: textureLoader.load(overlay),
            }));
            this.accessories = this.opts.accessories.map((accessory) => Object.assign({}, accessory, {
                textures: accessory.url.map((url) => textureLoader.load(url)),
                current: _.isUndefined(accessory.current) ? 0 : accessory.current,
            }));
            this.optionals = this.opts.optionals.map((optional) => Object.assign({}, optional, {
                visible: _.isUndefined(optional.visible) ? true : optional.visible,
            }));

            if (this.parts.length === 0 && this.overlays.length === 0 && this.accessories.length === 0) {
                throw new Error('Cannot load a model without at least one part, one overlay or one accessory.');
            }
        });
    }

    export() {
        const result = _.cloneDeep(this.opts);
        result.parts.forEach((part, index) => {
            part.color = this.parts[index].color;
        });
        result.groups.forEach((group, index) => {
            group.items = this.groups[index].items.map((item) => item.export());
        });
        result.accessories.forEach((accessory, index) => {
            accessory.current = this.accessories[index].current;
        });
        result.optionals.forEach((optional, index) => {
            optional.visible = this.optionals[index].visible;
        });
        return result;
    }

    setPartColor(partName, color) {
        const part = _.find(this.parts, { key: partName });
        if (!part) {
            throw new Error(`Cannot find model part "${partName}".`);
        }
        part.color = parseInt(color, 16);
        part.mesh.material.color = new THREE.Color(part.color);
    }

    setAccessory(key, index) {
        const accessory = _.find(this.accessories, { key });
        accessory.current = index;
        for (let i = 0; i < accessory.meshes.length; i++) {
            accessory.meshes[i].visible = i === index;
        }
    }

    setOptional(key, value) {
        const optional = _.find(this.optionals, { key });
        optional.visible = value;
        for (let i = 0; i < optional.objects.length; i++) {
            this.scene.getObjectByName(optional.objects[i]).visible = optional.visible;
        }
    }

    init() {
        if (this.rtScene) {
            throw new Error('init() already executed.');
        }

        this.rtScene = new THREE.Scene();
        this.rtScene.background = new THREE.Color(0);
        this.camera = new THREE.OrthographicCamera(-.5, .5, -.5, .5, 1, 1000);
        this.camera.position.set(0, 0, 1);
        this.camera.lookAt(0, 0, 0);
        this.camera.updateProjectionMatrix();
        this.rtScene.add(this.camera);

        const geometry = new THREE.PlaneGeometry();

        this.parts.forEach((part) => {
            const material = new THREE.MeshBasicMaterial({
                side: THREE.DoubleSide, alphaMap: part.texture, transparent: true, depthWrite: false, color: part.color,
            });
            part.mesh = new THREE.Mesh(geometry, material);
            this.rtScene.add(part.mesh);
        });
    }

    initGroups() {
        this.groups = this.opts.groups.map((group) => new Group(
            group.objects.map((objectName) => this.scene.getObjectByName(objectName)),
            this.RENDER_TARGET_WIDTH, this.RENDER_TARGET_HEIGHT,
            group.items, group.snaplines, group.drawable, group.transparent
        ));

        this.groups.forEach((group) => {
            group.objects.forEach((object) => {
                object.material = object.material.clone();
                object.material.map = group.renderTarget.texture;
            });
            this.rtScene.add(group.mesh);
            group.draw();
        });

        const geometry = new THREE.PlaneGeometry();

        this.overlays.forEach((overlay) => {
            const material = new THREE.MeshBasicMaterial({
                side: THREE.DoubleSide, map: overlay.texture, transparent: true, depthWrite: false,
            });
            const mesh = new THREE.Mesh(geometry, material);
            this.rtScene.add(mesh);
        });

        this.accessories.forEach((accessory) => {
            accessory.meshes = accessory.textures.map((texture, index) => {
                const material = new THREE.MeshBasicMaterial({
                    side: THREE.DoubleSide, map: texture, transparent: true, depthWrite: false,
                });
                const mesh = new THREE.Mesh(geometry, material);
                mesh.visible = index === accessory.current;
                this.rtScene.add(mesh);
                return mesh;
            });
        });

        this.optionals.forEach((optional) => {
            for (let i = 0; i < optional.objects.length; i++) {
                this.scene.getObjectByName(optional.objects[i]).visible = optional.visible;
            }
        });
    }

    dispose() {
        if (this.groups) {
            this.groups.forEach((group) => group.dispose());
            delete this.groups;
        }
    }

    render() {
        this.groups && this.groups.forEach((group) => {
            this.groups.forEach((innerGroup) => innerGroup.mesh.visible = group === innerGroup);
            this.renderer.render(this.rtScene, this.camera, group.renderTarget);
        });
    }

    getObjects() {
        return _.flatten(this.groups.map((group) => group.objects));
    }

    getItems() {
        return _.flatten(this.groups.map((group) => group.items));
    }

    getContainingGroupIndex(object) {
        return _.findIndex(this.groups, (group) => group.objects.includes(object));
    }

    getIntersections(position, camera) {
        const mouse = new THREE.Vector2((position.x * 2) - 1, - (position.y * 2) + 1);
        raycaster.setFromCamera(mouse, camera);
        return raycaster.intersectObjects(this.getObjects());
    }

    getSingleIntersect(position, camera) {
        const intersects = this.getIntersections(position, camera);
        if (intersects.length > 0 && intersects[0].uv) {
            const point = intersects[0].point;
            const uv = intersects[0].uv;
            intersects[0].object.material.map.transformUv(uv);
            const groupIndex = this.getContainingGroupIndex(intersects[0].object);
            const group = this.groups[groupIndex];
            return {
                group,
                groupIndex,
                point,
                uv,
            };
        }
        return null;
    }

    getSelectableObjectFromPosition(position, camera) {
        const intersect = this.getSingleIntersect(position, camera);
        if (intersect) {
            const { group, uv } = intersect;
            if (group) {
                // Reverse loop to select the foremost element first
                for (let i = group.items.length - 1; i >= 0; i--) {
                    const item = group.items[i];
                    if (item.selected && !item.pinned) {
                        for (const button in item.selectionContours) {
                            if (pointInPolygon(item.selectionContours[button], [uv.x, 1 - uv.y]) <= 0) {
                                return {
                                    button,
                                    item,
                                    group,
                                };
                            }
                        }
                    }

                    if (pointInPolygon(item.contour, [uv.x, 1 - uv.y]) <= 0) {
                        return {
                            item,
                            group,
                        };
                    }
                }
            }
        }
        return null;
    }

    getSelectableObjectFromId(id) {
        for (const group of this.groups) {
            for (const item of group.items) {
                if (item.id === id) {
                    return { group, item };
                }
            }
        }
        return null;
    }

    addTextAsync(opts, groupIndex, point, uv) {
        this.groups[groupIndex].addItem(new TextItem(opts, point, uv));
    }

    addImageAsync(opts, groupIndex, point, uv) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                this.groups[groupIndex].addItem(new ImageItem({ img, url: opts.url }, point, uv));
                resolve();
            };
            img.onerror = () => {
                reject();
            };
            img.src = opts.url;
        });
    }

    updateItem(id, opts) {
        for (const group of this.groups) {
            for (const item of group.items) {
                if (item.id === id) {
                    item.update(opts);
                    group.draw();
                    return;
                }
            }
        }
        throw new Error(`Cannot find item with id="${id}".`);
    }

    removeItem(id) {
        for (const group of this.groups) {
            for (const item of group.items) {
                if (item.id === id) {
                    group.removeItem(item);
                    return;
                }
            }
        }
        throw new Error(`Cannot find item with id="${id}".`);
    }

    snap(uv, lines) {
        const result = uv.clone();
        for (const line of lines) {
            if (line.x) {
                const delta = Math.abs(uv.x - line.x);
                if (delta < line.delta) {
                    result.setX(line.x);
                }
            }
            if (line.y) {
                const delta = Math.abs(uv.y - line.y);
                if (delta < line.delta) {
                    result.setY(line.y);
                }
            }
        }
        return result;
    }
}
