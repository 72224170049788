import cloneDeep from 'lodash/cloneDeep';
import { getColor } from './AXSHARED';
import { IS_MOBILE } from '../constants';

export const salopetteCodes = [
  'Calzamaglia_A191_CLASSIC',
  'Calzamaglia_A391_ELITE',
  'Pantalone_A222_TEAM',
  'Pantalone_A232_TEAM_3.4',
  'Pantalone_A283_TEAM_INV_L',
  'Salopette_A121_CLASSIC',
  'Salopette_A221_TEAM',
  'Salopette_A231_3.4',
  'Salopette_A281_INVERNALE',
  'Salopette_A321_ELITE',
  'Salopette_A421_PERFORMANCE',
  'Salopette_A422_PREMIUM',
  'Salopette_A481_PERFORMANCE_INV',
  'Salopette_AX122_SPRINT',
];
const noLycraCodes = [
  'Pantalone_A283_TEAM_INV_L',
  'Salopette_A421_PERFORMANCE',
  'Salopette_A422_PREMIUM',
  'Salopette_A481_PERFORMANCE_INV',
];
const codesWithSharedGraphics = [
  'Calzamaglia_A191_CLASSIC',
  'Calzamaglia_A391_ELITE',
  'Pantalone_A222_TEAM',
  'Pantalone_A232_TEAM_3.4',
  'Pantalone_A283_TEAM_INV_L',
  'Salopette_A121_CLASSIC',
  'Salopette_A221_TEAM',
  'Salopette_A231_3.4',
  'Salopette_A281_INVERNALE',
  'Salopette_A321_ELITE',
  'Salopette_A421_PERFORMANCE',
  'Salopette_A422_PREMIUM',
  'Salopette_A481_PERFORMANCE_INV',
  'Salopette_AX122_SPRINT',
];
const ALL_MODELS_SHARED_PATH = 'Calzamaglia_Pantalone_Salopette_SHARED';
// const ALL_MODELS_SHARED_OVERLAYS = [
//   `models/${ALL_MODELS_SHARED_PATH}/overlays/FONDO_GAMBA/CUCITURE_FONDO_GAMBA_NERO.png`
// ];
const ALL_MODELS_SHARED_OVERLAYS = [];
// eslint-disable-next-line max-len
// const CUCITURA_4AGHI_NERA_OVERLAY = `models/${ALL_MODELS_SHARED_PATH}/overlays/4AGHI/4AGHI-NERA.png`;
const PARTS_NUMBER_BY_GRAPHICS = {
  BASE: 5,
  BALDO: 3,
  BERNINA: 4,
  CEVEDALE: 3,
  CIMONE: 3,
  DUFOUR: 2,
  GOTTARDO: 3,
  MONVISO: 3,
  OLIMPO: 2,
  SASSOLUNGO: 4,
};
const PART_WITH_LOGO_BY_GRAPHICS = {
  BASE: 3,
  BALDO: 1,
  BERNINA: 1,
  CEVEDALE: 1,
  CIMONE: 2,
  DUFOUR: 1,
  GOTTARDO: 1,
  MONVISO: 1,
  OLIMPO: 1,
  SASSOLUNGO: 1,
};
const GRAPHICS_NAMES = Object.keys(PARTS_NUMBER_BY_GRAPHICS);
// const CUCITURA_4AGHI_ACCESSORY = {
//   key: 'Cucitura quattro aghi',
//   url: [
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-NERA.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-ARANCIO-FLUO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-AZZURRO-ITALIA.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-BIANCO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-BLU-MARINE.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-CICLAMINO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-GIALLO-FLUO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-GRIGIO-TITANIUM.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-ROSA-FLUO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-ROSSA.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-ROYAL.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-VERDE-FLUO.png`,
//     `models/${ALL_MODELS_SHARED_PATH}/accessories/4AGHI/4AGHI-VERDE-PRATO.png`,
//   ],
// };
const LYCRA_ACCESSORY = {
  key: 'Lycra',
  url: [
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-NERA.png`,
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-AZZURRO-ITALIA.png`,
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-BIANCA.png`,
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-BLU-MARINE.png`,
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-ROSSA.png`,
    `models/${ALL_MODELS_SHARED_PATH}/accessories/LYCRA/LYCRA-ROYAL.png`,
  ],
};

export const shortenCode = code => code.split('_').slice(1).join(' ');

const getModelPath = (modelName) => {
  if (codesWithSharedGraphics.includes(modelName)) {
    return ALL_MODELS_SHARED_PATH;
  }
  return modelName;
};

// eslint-disable-next-line no-plusplus,no-param-reassign
const getArrayOf = (length = 0) => Array(length).fill(0).map((_, i) => ++i);
const getPartKey = colorNumber => `Colore ${colorNumber}`;
const getPartUrl = (modelPath, graphicsName, colorNumber) => `models/${modelPath}/GRAFICHE/${graphicsName}/COLORE-${colorNumber}.png`;

const sharedPartsByModelAndGraphics = (modelName, graphicsName) => {
  const modelPath = getModelPath(modelName);
  return getArrayOf(PARTS_NUMBER_BY_GRAPHICS[graphicsName])
    .map(colorNumber => ({
      key: getPartKey(colorNumber),
      url: getPartUrl(modelPath, graphicsName, colorNumber),
      ...getColor(colorNumber),
      partAccessories: colorNumber === PART_WITH_LOGO_BY_GRAPHICS[graphicsName] ? ['loghi'] : undefined,
    }));
};

const getOverlays = (/* modelType */) => {
  const overlays = [...ALL_MODELS_SHARED_OVERLAYS];
  // if (modelType === 'Salopette') {
  //   overlays.push(CUCITURA_4AGHI_NERA_OVERLAY);
  // }
  return overlays;
};

const getAccessories = (modelName) => {
  const accessories = [
    {
      key: 'loghi',
      url: [
        `models/${ALL_MODELS_SHARED_PATH}/accessories/LOGHI/LOGHI-BIANCHI.png`,
        `models/${ALL_MODELS_SHARED_PATH}/accessories/LOGHI/LOGHI-NERI.png`,
      ],
      hidden: true,
    },
  ];
  if (!noLycraCodes.includes(modelName)) {
    accessories.push(cloneDeep(LYCRA_ACCESSORY));
  }
  return accessories;
};

const getCameraRatio = (modelType) => {
  switch (modelType) {
    case 'Calzamaglia':
    case 'Salopette':
      return 1.7;
    case 'Pantalone':
      return 1.75;
    default:
      return 1;
  }
};

const getCameraMinDistance = (modelType) => {
  switch (modelType) {
    case 'Calzamaglia':
      return 0.2;
    case 'Pantalone':
    case 'Salopette':
      return 0.4;
    default:
      return 1;
  }
};

export default (modelName) => {
  // eslint-disable-next-line global-require,import/no-dynamic-require
  const { nodes } = require(`../nodes/${modelName}.json`);
  const prefix = `/models/${modelName}`;
  const modelType = modelName.split('_')[0];
  const variants = GRAPHICS_NAMES
    .map(graphicsName => ({
      id: graphicsName.toLowerCase(),
      name: `${graphicsName.charAt(0)}${graphicsName.slice(1).toLowerCase()}`,
      image: `/images/${modelName}/${graphicsName}.png`,
      parts: sharedPartsByModelAndGraphics(modelName, graphicsName),
      overlays: getOverlays(modelType),
      accessories: getAccessories(modelName, modelType),
    }));
  const noLycraAccessory = noLycraCodes.includes(modelName);
  const config = {
    url: `${prefix}/${modelName}.gltf`,
    parts: variants[0].parts,
    overlays: variants[0].overlays,
    accessories: variants[0].accessories,
    groups: IS_MOBILE ? [] : nodes.map(({ name }) => ({ transparent: name.includes('Cucitur'), objects: [name] })),
    optionals: [],
    cameraOptions: {
      cameraRatio: getCameraRatio(modelType),
      minDistance: getCameraMinDistance(modelType),
    },
    directionalLights: [
      { intensity: noLycraAccessory ? 1.0 : 0.3 },
      { intensity: noLycraAccessory ? 1.2 : 0.4 },
    ],
    hasNamedAccessories: true,
    hideAccessoriesTab: noLycraAccessory,
    noMobile: true,
  };

  return { config, variants };
};
