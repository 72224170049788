import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { I18n, Translate } from 'react-i18nify';
import { UncontrolledTooltip } from 'reactstrap';

const isItaly = (state = '') => {
  const country = state.trim().toLowerCase();
  return country === 'i' || country === 'it' || country === 'ita' || country.startsWith('ital');
};

const requiredValidator = value => (value || typeof value === 'number' ? undefined : I18n.t('VALIDATION.REQUIRED'));

const emailValidator = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? I18n.t('VALIDATION.EMAIL')
  : undefined);

const provinceValidator = (value, allValues) => {
  const { state = '' } = allValues;
  if (isItaly(state)) {
    return (value ? undefined : I18n.t('VALIDATION.REQUIRED'));
  }
  return undefined;
};

const termsValidator = value => (value ? undefined : I18n.t('VALIDATION.TERMS'));

const getErrorLabel = ({ touched, error, warning }, isCheckbox) => {
  if (!touched) return '';
  if (error) {
    return (
      <span style={{ color: 'red' }}>
        {isCheckbox && <br />}
        {error}
      </span>
    );
  }
  if (warning) return <span>{warning}</span>;
  return '';
};

const hasError = ({ touched, error, warning }) => touched && (error || warning);

const renderField = ({
  id = '',
  input,
  label,
  checkboxLabel,
  className,
  type,
  meta,
}) => (
  <div style={{ marginBottom: hasError(meta) ? '.5rem' : 'initial' }}>
    <input className={className || 'w-100'} {...input} id={id} placeholder={label} type={type} />
    {
      type === 'checkbox' && (
        // eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control
        <label
          style={{ marginBottom: hasError(meta) ? 'initial' : '.5rem' }}
          htmlFor={id}
        >
          <Translate value={checkboxLabel} dangerousHTML />
        </label>
      )
    }
    {getErrorLabel(meta, type === 'checkbox')}
  </div>
);

const CustomerForm = ({
  handleSubmit, hideSubmit, selectedState, tooltips,
}) => (
  <form onSubmit={handleSubmit} className="form-checkout" autoComplete="off">
    <div className="row">
      <div className="col-12">
        <Field
          name="name"
          component={renderField}
          type="text"
          required
          validate={requiredValidator}
          label={I18n.t('PLACEHOLDERS.NAME_AND_SURNAME')}
        />
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-6">
        <Field
          name="email"
          component={renderField}
          type="text"
          required
          validate={[requiredValidator, emailValidator]}
          label={I18n.t('PLACEHOLDERS.EMAIL')}
        />
      </div>
      <div className="col-6">
        <Field
          name="phone"
          component={renderField}
          type="text"
          required
          validate={requiredValidator}
          label={I18n.t('PLACEHOLDERS.PHONE')}
        />
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-6">
        <Field
          name="team"
          component={renderField}
          type="text"
          required
          validate={requiredValidator}
          label={I18n.t('PLACEHOLDERS.TEAM_NAME')}
        />
      </div>
      <div className="col-6">
        <Field
          name="teamMembersNumber"
          component={renderField}
          type="number"
          required
          validate={requiredValidator}
          label={I18n.t('PLACEHOLDERS.TEAM_MEMBERS')}
        />
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-6">
        <Field
          name="province"
          component={renderField}
          type="text"
          required
          validate={provinceValidator}
          label={isItaly(selectedState) ? I18n.t('PLACEHOLDERS.PROVINCE') : I18n.t('PLACEHOLDERS.PROVINCE_OPTIONAL')}
        />
      </div>
      <div className="col-6">
        <Field
          name="state"
          component={renderField}
          type="text"
          required
          validate={requiredValidator}
          label={I18n.t('PLACEHOLDERS.STATE')}
        />
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-12">
        <Field
          className="w-100 light-gray-border"
          name="notes"
          component="textarea"
          rows="5"
          type="text"
          placeholder={I18n.t('PLACEHOLDERS.NOTES')}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <Field
          id="terms"
          name="terms"
          component={renderField}
          className="m-r-10"
          checkboxLabel="TERMS_LABEL"
          validate={termsValidator}
          type="checkbox"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <Field
          id="statistics"
          name="statistics"
          component={renderField}
          className="m-r-10"
          checkboxLabel="STATISTICS_LABEL"
          type="checkbox"
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <Field
          id="marketing"
          name="marketing"
          component={renderField}
          className="m-r-10"
          checkboxLabel="MARKETING_LABEL"
          type="checkbox"
        />
      </div>
    </div>
    <br />
    {
      !hideSubmit && (
        <div>
          <button type="submit" className="uppercase" id="send-quote-request">
            <Translate value="SUBMIT_FORM" />
          </button>
          {
            tooltips && (
              <UncontrolledTooltip placement="bottom" target="send-quote-request">
                <Translate value="TOOLTIPS.SEND_REQUEST" />
              </UncontrolledTooltip>
            )
          }
        </div>
      )
    }
  </form>
);

CustomerForm.propTypes = {
  handleSubmit: PropTypes.func,
  hideSubmit: PropTypes.bool,
  selectedState: PropTypes.string,
  tooltips: PropTypes.bool.isRequired,
};

CustomerForm.defaultProps = {
  handleSubmit: () => null,
  hideSubmit: false,
  selectedState: '',
};

const reduxCustomerForm = reduxForm({ form: 'contact' })(CustomerForm);

const selector = formValueSelector('contact');

export default connect((state) => {
  const selectedState = selector(state, 'state');
  return { selectedState };
})(reduxCustomerForm);
