import _ from 'lodash';
import Item from './Item';
import {EDITABLE_TEXT_PROPS, OBJECT_TYPES} from '../constants';

export default class TextItem extends Item {
    constructor(opts, point, uv) {
        super(OBJECT_TYPES.TEXT, point, uv);
        Object.assign(this, {color: 0, size: 32, font: 'Arial'}, opts);
    }

    toJson() {
        return Object.assign(super.toJson(), _.pick(this, EDITABLE_TEXT_PROPS));
    }

    update(opts) {
        super.update(opts);
        Object.assign(this, _.pick(opts, EDITABLE_TEXT_PROPS));
    }
}
