import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import PrevModel from './PrevModel';
import { list } from '../../api/models';

class ListModels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
    };
  }

  componentDidMount() {
    const { alert } = this.props;

    list()
      .then(models => this.setState({ models }))
      .catch((err) => {
        alert.error(I18n.t('ERROR'));
        Sentry.captureException(err);
      });
  }

  render() {
    const { models } = this.state;
    return (
      <div className="row">
        {
          models.map(({ code, description, image, baseVariant }, index) => (
            <PrevModel
              key={code}
              code={code}
              description={description}
              image={image}
              baseVariant={baseVariant}
              index={index}
            />
          ))
        }
      </div>
    );
  }
}

ListModels.propTypes = {
  alert: PropTypes.shape({
    error: PropTypes.func,
  }) /* eslint react/require-default-props: 0 */,
};
export default withAlert(ListModels);
