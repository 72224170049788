/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import {
  Label,
  Col,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  AvForm,
  AvInput,
  AvFeedback,
  AvGroup,
} from 'availity-reactstrap-validation';
import { number2Color } from '../../helper/colors';
import PantonePicker from '../PantonePicker';
import { FONTS } from '../../constants';

class TextForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bold: !!props.text.bold,
      font: props.text.font || FONTS[0].value,
      italic: !!props.text.italic,
      textInput: '',
      hex: '#2e2a26',
      pantone: '',
      picker: false,
    };
  }

  componentDidMount() {
    const { text: textObj } = this.props;
    if (isEmpty(textObj)) return;
    const { color, pantone } = textObj || {};
    this.setState({ hex: number2Color(color), pantone });
  }

  changeColor(newColor) {
    const { onSubmit, text } = this.props;
    const textObj = text || {};
    const { id } = textObj;
    const { hex, name: pantoneName, pantone } = newColor;
    this.setState({ hex, pantone, picker: false });
    if (!id) return;
    onSubmit({
      ...textObj,
      hex,
      pantone,
      pantoneName,
    });
  }

  changeText(text, font, bold, italic) {
    const { hex, pantone } = this.state;
    const { onSubmit, text: textObj } = this.props;
    const { id } = textObj || {};
    onSubmit({
      id: textObj.id,
      text,
      font,
      bold,
      italic,
      hex,
      pantone,
    });
    const nextState = { picker: false };
    if (!id) nextState.textInput = '';
    this.setState(nextState);
  }

  render() {
    const {
      bold,
      font,
      hex,
      italic,
      pantone,
      picker,
      textInput,
    } = this.state;
    const {
      text: textObj,
      onRemoveText,
      onTextZoom,
      onTextRotate,
      disabled,
      textIndex,
      tooltips,
    } = this.props;
    const {
      id,
      angle,
      scale,
      text,
    } = textObj;

    const defaultValues = {
      text,
    };

    return (
      <div className="p-t-10">
        <AvForm
          model={defaultValues}
          className="text-form"
          onValidSubmit={(event, values) => {
            this.changeText(values.text, values.font, values.bold, values.italic);
          }}
        >
          <AvGroup row>
            <Label sm={4} for="exampleEmail">
              <span
                id={`text-color-${textIndex}`}
                className="color-preview"
                style={{ backgroundColor: `${hex}` }}
                onClick={() => !disabled && this.setState({ picker: !picker })}
                role="presentation"
              >
                {' '}
              </span>
              <Translate value="TEXT" />
            </Label>
            <Col xs={7} sm={5}>
              <AvInput
                type="text"
                name="text"
                value={textInput}
                onChange={(event, value) => this.setState({ textInput: value })}
                required
                disabled={disabled}
                id={`enter-text-${textIndex}`}
                autoComplete="off"
              />
              <AvFeedback><Translate value="FIELD_REQUIRED" /></AvFeedback>
              <AvInput
                type="select"
                value={font}
                className="m-t-10"
                name="font"
                id={`select-font-${textIndex}`}
                onChange={(event, value) => this.setState({ font: value })}
              >
                {
                  sortBy(FONTS, ['name']).map(({ name, value }) => <option key={name} value={value}>{name}</option>)
                }
              </AvInput>
              <AvGroup check row className="m-t-10" style={{ display: 'flex' }}>
                <Col>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="bold"
                      value={bold}
                      onChange={(event, value) => this.setState({ bold: value })}
                    />
                    <Translate value="BOLD" />
                  </Label>
                </Col>
                <Col>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="italic"
                      value={italic}
                      onChange={(event, value) => this.setState({ italic: value })}
                    />
                    <Translate value="ITALICS" />
                  </Label>
                </Col>
              </AvGroup>
            </Col>
            <Col xs={5} sm={3}>
              {
                !id && (
                  <Button disabled={disabled} id={`apply-text-${textIndex}`}>
                    <i className="fas fa-plus-circle" role="presentation" />
                  </Button>
                )
              }
              {
                id && (
                  <div>
                    <Button id={`edit-text-${textIndex}`}>
                      <i className="fas fa-check-circle" role="presentation" />
                    </Button>
                    <Button
                      type="button"
                      onMouseDown={() => onTextZoom(id, scale, true)}
                      onMouseUp={() => onTextZoom(id)}
                      onMouseLeave={() => onTextZoom()}
                      id={`text-zoom-in-${textIndex}`}
                    >
                      <i className="fas fa-search-plus" role="presentation" />
                    </Button>
                    <Button
                      type="button"
                      onMouseDown={() => onTextZoom(id, scale)}
                      onMouseUp={() => onTextZoom(id)}
                      onMouseLeave={() => onTextZoom()}
                      id={`text-zoom-out-${textIndex}`}
                    >
                      <i className="fas fa-search-minus" role="presentation" />
                    </Button>
                    <Button
                      id={`rotate-clockwise-text-${textIndex}`}
                      key="rotate-clockwise"
                      onMouseDown={() => onTextRotate(id, angle, true)}
                      onMouseUp={() => onTextRotate(id)}
                      onMouseLeave={() => onTextRotate()}
                    >
                      <i className="fas fa-undo vert-spec" role="presentation" />
                    </Button>
                    <Button
                      id={`rotate-counterclockwise-text-${textIndex}`}
                      key="rotate-counterclockwise"
                      onMouseDown={() => onTextRotate(id, angle)}
                      onMouseUp={() => onTextRotate(id)}
                      onMouseLeave={() => onTextRotate()}
                    >
                      <i className="fas fa-undo" role="presentation" />
                    </Button>
                    <Button type="button" onClick={() => onRemoveText(id)} id={`remove-text-${textIndex}`}>
                      <i className="fas fa-trash" role="presentation" />
                    </Button>
                  </div>
                )
              }

            </Col>
          </AvGroup>
        </AvForm>
        {
          picker && (
            <PantonePicker
              tooltips={tooltips}
              itemIndex={textIndex}
              color={{ hex, pantone }}
              onChange={newColor => this.changeColor(newColor)}
            />
          )
        }
        {
          tooltips && [
            <UncontrolledTooltip key={0} placement="bottom" target={`text-color-${textIndex}`}>
              <Translate value="TOOLTIPS.SELECT_TEXT_COLOR" />
            </UncontrolledTooltip>,
            <UncontrolledTooltip key={1} placement="bottom" target={`enter-text-${textIndex}`}>
              <Translate value="TOOLTIPS.ENTER_TEXT_HERE" />
            </UncontrolledTooltip>,
            <UncontrolledTooltip key={2} placement="bottom" target={`select-font-${textIndex}`}>
              <Translate value="TOOLTIPS.SELECT_FONT" />
            </UncontrolledTooltip>,
          ]
        }
        {
          tooltips && !id && (
            <UncontrolledTooltip placement="bottom" target={`apply-text-${textIndex}`}>
              <Translate value="TOOLTIPS.APPLY_TEXT" />
            </UncontrolledTooltip>
          )
        }
        {
          tooltips && id && [
            <UncontrolledTooltip key={0} placement="bottom" target={`edit-text-${textIndex}`}>
              <Translate value="TOOLTIPS.CONFIRM_EDIT" />
            </UncontrolledTooltip>,
            <UncontrolledTooltip key={1} placement="bottom" target={`remove-text-${textIndex}`}>
              <Translate value="TOOLTIPS.REMOVE_TEXT" />
            </UncontrolledTooltip>,
          ]
        }
      </div>
    );
  }
}

TextForm.propTypes = {
  textIndex: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemoveText: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onTextRotate: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onTextZoom: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.shape({
    bold: PropTypes.bool,
    color: PropTypes.number,
    font: PropTypes.string,
    id: PropTypes.string,
    italic: PropTypes.bool,
    text: PropTypes.string,
  }),
  tooltips: PropTypes.bool.isRequired,
};

TextForm.defaultProps = {
  text: {},
  onRemoveText: undefined,
  disabled: false,
};

export default TextForm;
