import _ from 'lodash';
import Item from './Item';
import {OBJECT_TYPES} from '../constants';

export default class ImageItem extends Item {
    constructor(opts, point, uv) {
        super(OBJECT_TYPES.IMAGE, point, uv);
        Object.assign(this, opts);
    }

    toJson() {
        return Object.assign(super.toJson(), _.pick(this, ['url']));
    }

    update(opts) {
        super.update(opts);
        Object.assign(this, _.pick(opts, ['url']));
    }
}
