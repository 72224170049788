import { I18n } from 'react-i18nify';
import * as Sentry from '@sentry/browser';

const MIN_DIMENSION = 0;

export const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const checkDimension = file => new Promise((resolve, reject) => {
  const error = new Error(I18n.t('ERROR_IMAGE_MIN_DIMENSION', { dimension: MIN_DIMENSION }));

  function handler() {
    if (this.width < MIN_DIMENSION || this.height < MIN_DIMENSION) {
      return reject(error);
    }
    return resolve();
  }

  getBase64(file)
    .then((result) => {
      const img = new Image();
      img.onload = handler;
      img.src = result;
    })
    .catch((err) => {
      Sentry.captureException(err);
      reject(err);
    });
});
