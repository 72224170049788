import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { I18n } from 'react-i18nify';

const { REACT_APP_ENABLE_RETURN_URL } = process.env;
const enableReturnUrl = REACT_APP_ENABLE_RETURN_URL === 'true';

const NavPreview = () => (
  <Navbar color="light" light expand="md">
    <NavbarBrand href={enableReturnUrl ? I18n.t('THANK_YOU_RETURN_URL') : '/'}>
      <img src="/images/alka-logo.png" alt="Alka Sport" className="img-fluid" />
    </NavbarBrand>
  </Navbar>
);


export default NavPreview;
