import { CLEAR, SET_MODEL } from '../actions/names';

const initialState = '';

const reducer = (state = initialState, { type, model = '' }) => {
  switch (type) {
    case SET_MODEL:
      return model;
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
