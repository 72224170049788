import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { throttle } from 'lodash';
import reducers from './reducers';
import { loadState, saveState } from './localStorage';

const enhancers = [];
const middleware = [
  thunk,
];

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  /* eslint-enable */

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const persistedState = loadState();

const store = createStore(
  reducers,
  persistedState,
  composedEnhancers,
);

store.subscribe(throttle(() => {
  const state = store.getState();
  saveState({
    model: state.model,
    variant: state.variant,
    config: state.config,
    tooltips: state.tooltips,
  });
}, 1000));

export default store;
