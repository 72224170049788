import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loading-overlay';
import { Translate, I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { withAlert } from 'react-alert';
import * as Sentry from '@sentry/browser';
import NavCustomer from './NavCustomer';
import CustomerForm from './CustomerForm';
import TShirtViewer from '../../configurator/TShirtViewer';
import { get } from '../../api/models';
import { clear } from '../../actions/actions';
import { addPantonesToConfig } from '../../helper/colors';

const { REACT_APP_API_BACKEND_URL = '', REACT_APP_API_BACKEND_API_KEY = '' } = process.env;
const headers = { 'x-api-key': REACT_APP_API_BACKEND_API_KEY };

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      alert,
      config: configInStore,
      model,
    } = this.props;

    this.configurator = new TShirtViewer(document.getElementById('configurator'));
    this.configurator.on('modelLoaded', () => {
      this.setState({
        customerPreview: this.configurator.exportPng(),
        loading: false,
      });
    });

    window.addEventListener('resize', () => {
      if (!this.configurator) return;
      this.configurator.resize();
    });

    get(model)
      .then(({ config: defaultModelConfig = {} } = {}) => {
        const config = isEmpty(configInStore) ? defaultModelConfig : configInStore;
        this.setState({ config, loading: true });
        this.configurator.loadModelAsync(config);
      })
      .catch((err) => {
        alert.error(I18n.t('ERROR'));
        Sentry.captureException(err);
      });
  }

  componentWillUnmount() {
    const { configurator } = this;
    if (!configurator) return;
    configurator.removeAllListeners();
    configurator.dispose();
    delete this.configurator;
  }

  submit(customer) {
    const {
      alert, dispatchClear, history, model, modelName, variant,
    } = this.props;
    const { config: configFromState = {}, customerPreview } = this.state;
    this.setState({ loading: true });
    const config = addPantonesToConfig(configFromState);
    const body = {
      config, customer, customerPreview, model, modelName, variant,
    };
    const onUploadProgress = ({ loaded, total }) => {
      this.setState({ loaded: Math.round(loaded * 100 / total) });
    };
    const options = { headers, onUploadProgress };
    axios.post(`${REACT_APP_API_BACKEND_URL}/quote-submit`, body, options)
      .then(() => {
        this.setState({ hideSubmit: true });
        dispatchClear();
        history.push('/thank-you');
      })
      .catch(({ response = {} } = {}) => {
        const { status } = response;
        const isValidationError = status === 400;
        alert.error(I18n.t(`SUBMIT.${isValidationError ? 'VALIDATION' : 'ERROR'}`));
        if (isValidationError) return;
        Sentry.captureMessage(`Quote submission failed with response: ${JSON.stringify(response)}`);
      })
      .then(() => this.setState({ loaded: 0, loading: false }));
  }

  render() {
    const {
      loaded,
      loading,
      hideSubmit,
    } = this.state;
    const {
      history,
      tooltips,
    } = this.props;
    return (
      <Loadable
        active={loading}
        text={I18n.t('LOADING') + (loaded ? ` ${loaded}%` : '')}
      >
        <NavCustomer history={history} />
        <section className="cont-configurator">
          <div className="row configurator-background">
            <div className="col-11 col-sm-11 col-md-6 col-lg-7">
              <div id="configurator" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 form-height">
              <h4><Translate value="FORM_TITLE" /></h4>
              <hr />
              <Translate value="FORM_SUBTITLE" dangerousHTML />
              <br />
              <br />
              <CustomerForm
                onSubmit={formData => this.submit(formData)}
                hideSubmit={hideSubmit}
                tooltips={tooltips}
              />
            </div>
          </div>
        </section>
      </Loadable>
    );
  }
}

Customer.propTypes = {
  alert: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func,
  }), /* eslint react/require-default-props: 0 */
  config: PropTypes.object.isRequired, /* eslint react/forbid-prop-types: 0 */
  dispatchClear: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  model: PropTypes.string.isRequired,
  modelName: PropTypes.string,
  variant: PropTypes.string.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  model: state.model,
  modelName: state.modelName,
  config: state.config,
  variant: state.variant,
  tooltips: state.tooltips,
});

const mapDispatchToProps = {
  dispatchClear: () => clear(),
};


export default connect(mapStateToProps, mapDispatchToProps)(withAlert(Customer));
