import {OBJECT_TYPES} from '../constants';
import loadImageAsync from './loadImageAsync';

export default async function prepareDataAsync(opts) {
    for (const group of opts.groups) {
        if (group.items) {
            for (const item of group.items) {
                switch (item.type) {
                    case OBJECT_TYPES.IMAGE:
                        await loadImageAsync(item);
                        break;
                    default:
                        break;
                }
            }
        }
    }
}
