/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';
import { UncontrolledTooltip } from 'reactstrap';
import { setModel } from '../../actions/model';
import { setVariant } from '../../actions/variant';
import { clear } from '../../actions/actions';

const getConfigurationUrl = code => `/configuration?model=${encodeURIComponent(code)}`;

const PrevModel = ({
  code, description, history, image, onClick, baseVariant, index, tooltips,
}) => (
  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
    <div id={`selectModelButton${index}`}>
      <div
        className="prev-product"
        role="button"
        onClick={() => { onClick(code, baseVariant); history.push(getConfigurationUrl(code)); }}
        onKeyPress={() => null}
      >
        <div className="img-model" style={{ backgroundImage: `url("${image}")` }} />
        <p>
          <Translate value="MODEL_CODE" />
        </p>
        <h2>{code}</h2>
        <h6>{description || ''}</h6>
        <button type="button" className="btn">
          <Translate value="SELECT" />
        </button>
      </div>
    </div>
    {
      tooltips && (
        <UncontrolledTooltip placement="bottom" target={`selectModelButton${index}`}>
          <Translate value="TOOLTIPS.SELECT_MODEL_1" />
          { code }
          <Translate value="TOOLTIPS.SELECT_MODEL_2" />
        </UncontrolledTooltip>
      )
    }
  </div>
);

PrevModel.propTypes = {
  code: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  description: PropTypes.string,
  baseVariant: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tooltips: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  tooltips: state.tooltips,
});

const mapDispatchToProps = dispatch => ({
  onClick: (model, baseVariant) => {
    dispatch(clear());
    dispatch(setModel(model));
    dispatch(setVariant(baseVariant));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrevModel));
