import React from 'react';
import AlertTemplate from 'react-alert-template-basic';

const Basic = (props) => {
  const { style } = props;
  style.width = 'initial';
  style.textTransform = 'initial';
  return (<AlertTemplate {...props} />);
};

export default Basic;
