import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { I18n } from 'react-i18nify';
import { MODES } from '../../configurator/constants';
import 'react-toastify/dist/ReactToastify.css';

const isPickMode = (mode) => {
  const { PICK_IMAGE_POSITION, PICK_TEXT_POSITION } = MODES;
  return mode === PICK_IMAGE_POSITION || mode === PICK_TEXT_POSITION;
};

class Banner extends Component {
  constructor(props) {
    super(props);
    this.id = '';
    this.config = {
      position: toast.POSITION.TOP_LEFT,
      autoClose: false,
      closeButton: false,
      draggable: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { mode } = this.props;
    const { mode: prevMode } = prevProps;
    if (mode === prevMode) return;
    const pickMode = isPickMode(mode);
    const wasPickMode = isPickMode(prevMode);
    if (wasPickMode && pickMode) return;

    if (pickMode) {
      this.id = toast(I18n.t(mode), this.config);
    }

    if (!pickMode && this.id !== '') {
      toast.dismiss(this.id);
      this.id = '';
    }
  }

  render() {
    return (
      <div className="banner-mode">
        <ToastContainer className="full-width-top" autoClose={false} closeButton={false} closeOnClick={false} />
      </div>
    );
  }
}

Banner.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default Banner;
