// See https://stackoverflow.com/a/1781750/2564990

function touchHandler(event) {
    const touches = event.changedTouches;
    const first = touches[0];
    let type;
    switch (event.type) {
        case 'touchstart':
            type = 'mousedown';
            break;
        case 'touchmove':
            type = 'mousemove';
            break;
        case 'touchend':
            type = 'mouseup';
            break;
        default:
            return;
    }

    const simulatedEvent = new MouseEvent(type, {
        view: window,
        bubbles: true,
        cancelable: true,
        screenX: first.screenX,
        screenY: first.screenY,
        clientX: first.clientX,
        clientY: first.clientY,
    });

    first.target.dispatchEvent(simulatedEvent);
    event.preventDefault();
}

module.exports.mapTouchEvents = (element) => {
    element.addEventListener('touchstart', touchHandler, true);
    element.addEventListener('touchmove', touchHandler, true);
    element.addEventListener('touchend', touchHandler, true);
    element.addEventListener('touchcancel', touchHandler, true);
};

module.exports.unmapTouchEvents = (element) => {
    element.removeEventListener('touchstart', touchHandler);
    element.removeEventListener('touchmove', touchHandler);
    element.removeEventListener('touchend', touchHandler);
    element.removeEventListener('touchcancel', touchHandler);
};
