import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import model from './model';
import modelName from './modelName';
import variant from './variant';
import config from './config';
// import configurationId from './configurationId';
// import texture from './texture';
// import colors from './colors';
// import texts from './texts';
// import images from './images';
// import customer from './customer';
import tooltips from './tooltips';

export default combineReducers({
  model,
  modelName,
  variant,
  config,
  // texture,
  // colors,
  // texts,
  // images,
  // customer,
  form,
  tooltips,
});
